import React from 'react';
import imgSuccess from '../../assets/img/icons/success.svg';
import { Link } from 'gatsby';

const AddressValidationInProgress = ({ formData, platform }) => {
  return (
    <div className="container step step-ok pl-5-md pr-5-md">
      <div className="row">
        <div className="col-12 text-align-center pt-6">
          <img src={imgSuccess} alt="Inscription - Email de validation" />
        </div>

        <div className="col-12 text-align-left pt-5">
          <div className="font-size-lg roboto bold font-size-md-sm text-align-center">
            Vos informations ont bien été enregistrées !
          </div>
          <div className="mt-5 font-size-sm-sm">
            Nous procédons dès à présent à la vérification de vos informations.
            Vous serez averti par mail dès qu'elles auront été validées par nos
            services.
          </div>

          <div className="mt-5 font-size-sm-sm">
            Si vous avez des questions sur le processus de validation ou
            souhaitez apporter des modifications à votre inscription,{' '}
            <Link to={'/contact/'} className="text-underline">
              contactez-nous
            </Link>{' '}
            en précisant votre adresse email et l'adresse postale renseignée
            lors de votre inscription.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressValidationInProgress;
