import React from 'react';
import imgSuccess from '../../assets/img/icons/success.svg';

const ValidEmail = ({ formData, platform }) => {
  return (
    <React.Fragment>
      <div className="container step step-ok ml-5-md mr-5-md">
        <div className="row">
          <div className="col-12 text-align-center pt-6">
            <img src={imgSuccess} alt="Inscription - Email de validation" />
          </div>

          <div className="col-12 text-align-left pt-5">
            <div className="roboto font-size-lg bold font-size-md-sm text-align-center pl-5 pr-5 pl-0-md pr-0-md text-align-left-md">
              Votre demande d'inscription a bien été prise en compte !
            </div>
            <div className="mt-5 font-size-md2">
              Un email de confirmation a été envoyé à l'adresse{' '}
              <span className="yellowTag bold">{formData.email}</span>.
            </div>

            <div className="mt-5 font-size-md2 bold">
              Il ne vous reste plus qu'à{' '}
              <span className="yellowTag">activer votre compte</span> en
              cliquant sur le lien transmis par email.
            </div>
            <div className="mt-5 font-size-md2">
              Dépêchez-vous, vos voisins vous attendent !
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidEmail;
