import React, { useState } from 'react';
import { Link } from 'gatsby';
import StepsList from './StepsList';
import FieldError from '../../components/Error/FieldError';
import ZoneMap from '../Map/ZoneMap';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Step1 = ({ formData, setFormData, validStep, platform }) => {
  const { property, zone } = formData;

  const [passType, setPassType] = useState(true);

  const partnerToDisplay = property?.partners?.find((p) => p.shouldDisplayIcon);

  const canSubmit = () =>
    formData.gender &&
    formData.firstName &&
    formData.lastName &&
    formData.email &&
    formData.password &&
    formData.consent;

  const zoneCoordinates = Array.isArray(zone.location?.coordinates?.[0])
    ? zone.location.coordinates[0].map((point) => {
        return {
          lng: point[0],
          lat: point[1],
        };
      })
    : zone.location?.coordinates;

  return (
    <>
      <div className="step step-1 container pl-5-md pr-5-md">
        <div className="row">
          <div className="col-12 text-align-left roboto pt-5">
            <div className="font-size-lg bold font-size-md-sm">
              Super ! Nous pouvons démarrer votre inscription !
            </div>
            <div className="mt-5 font-size-sm-sm">
              <p>
                En choisissant l'adresse{' '}
                <span className="yellowTag">{property.name}</span>, vous allez
                rejoindre le quartier{' '}
                <span className="yellowTag">{zone.name}</span> !
              </p>
              <p>
                Sur Alacaza, tous vos voisins utilisent leur véritable identité.
                Renseignez bien vos vrais noms et prénoms.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <form onSubmit={validStep}>
            <div className="col-6 col-12-sm">
              <div className="row">
                <div className="col-12">
                  <label className="radio-button justify-end">
                    <input
                      type="radio"
                      name="gender"
                      id="gender_woman"
                      value="WOMAN"
                      required
                      checked={formData.gender === 'WOMAN'}
                      onChange={(e) =>
                        setFormData({ ...formData, gender: e.target.value })
                      }
                    />
                    <span className="radio-background" />
                    <span className="radio-label">Femme</span>
                  </label>
                  <label className="radio-button justify-end">
                    <input
                      type="radio"
                      name="gender"
                      id="gender_man"
                      value="MAN"
                      required
                      checked={formData.gender === 'MAN'}
                      onChange={(e) =>
                        setFormData({ ...formData, gender: e.target.value })
                      }
                    />
                    <span className="radio-background" />
                    <span className="radio-label">Homme</span>
                  </label>
                  <FieldError fieldName="gender" errors={formData.errors} />
                </div>

                <div className="col-12">
                  <div className="inline size-50 pr-1">
                    <label htmlFor="firstName">Prénom *</label>
                    <FieldError
                      fieldName="firstName"
                      errors={formData.errors}
                    />
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      className="text-input"
                      minLength={2}
                      maxLength={40}
                      placeholder="Prénom"
                      autoComplete="given-name"
                      value={formData.firstName}
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, firstName: e.target.value })
                      }
                    />
                  </div>
                  <div className="inline size-50 pl-1">
                    <label htmlFor="lastName">Nom *</label>
                    <FieldError fieldName="lastName" errors={formData.errors} />
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      className="text-input"
                      minLength={2}
                      maxLength={40}
                      placeholder="Nom"
                      autoComplete="family-name"
                      required
                      value={formData.lastName}
                      onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="email">Email *</label>
                  <FieldError fieldName="email" errors={formData.errors} />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="text-input left-icon"
                    minLength={2}
                    maxLength={50}
                    placeholder="Email"
                    autoComplete="email"
                    value={formData.email}
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="password">Mot de passe *</label>
                  <FieldError fieldName="password" errors={formData.errors} />
                  <div className="relative">
                    <input
                      type={passType ? 'password' : 'text'}
                      name="password"
                      id="password"
                      className="text-input right-icon"
                      minLength={6}
                      maxLength={20}
                      placeholder="Mot de passe"
                      value={formData.password}
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                    <FontAwesomeIcon
                      className="input-right-icon-small"
                      width={0}
                      icon={passType ? faEyeSlash : faEye}
                      onClick={() => setPassType(!passType)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1 hide-sm"></div>

            {zoneCoordinates && (
              <div className="col-5 text-align-right text-align-center-md col-12-sm pt-3-sm">
                <div className="zone-map">
                  <ZoneMap zoneCoordinates={zoneCoordinates} />
                </div>
                {partnerToDisplay && (
                  <div className="partner text-align-center col-12-sm mt-5">
                    <img
                      className="col-3"
                      alt="partner-logo"
                      src={partnerToDisplay.icon}
                      width="100%"
                    />
                    <p className="col-9 font-size-sm-sm">
                      Cette application vous est offerte par&nbsp;
                      <span className="yellowTag">
                        {partnerToDisplay.name}
                      </span>{' '}
                      dans le cadre du projet&nbsp;
                      <span className="yellowTag">{zone.name}</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            <div className="step1-footer ml-auto mr-auto text-align-center">
              <div className="col-12">
                <div className="pt-2 pb-5 pl-3-md pr-3-md font-size-sm text-color-purple-grey text-align-left flex align-items-center">
                  <input
                    type="checkbox"
                    name="consent"
                    className="toggle-input mr-2"
                    onChange={(e) =>
                      setFormData({ ...formData, consent: e.target.checked })
                    }
                  />
                  J’accepte les &nbsp;
                  <Link to="/cgu/" className="text-underline">
                    conditions générales d’utilisation{' '}
                  </Link>
                  &nbsp;d’Alacaza.
                </div>

                <div className="font-size-lg">
                  <Link
                    to={`/inscription/${
                      platform ? '?&platform=' + platform : ''
                    }`}
                    className="btn secondary mr-2"
                  >
                    Annuler
                  </Link>
                  <button
                    className={
                      'btn ml-2 ' +
                      (formData.isLoading || !canSubmit() ? 'disabled' : '')
                    }
                    type="submit"
                    disabled={formData.isLoading}
                  >
                    Continuer
                  </button>
                </div>

                <StepsList selectedStep={1} nbOfSteps={3} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Step1;
