import React from 'react';
import StepsList from './StepsList';
import FieldError from '../../components/Error/FieldError';
import imgBuilding from '../../assets/img/icons/building.svg';

const initFloors = () => {
  let floors = [];
  floors.push({ value: '', label: '' });
  floors.push({ value: '0', label: 'Rez-de-chaussée' });

  for (let i = 1; i <= 80; i++) {
    floors.push({ value: '' + i, label: '' + i });
  }

  return floors;
};

const Step2 = ({ formData, setFormData, validStep }) => {
  const { property } = formData;
  const floors = initFloors();
  const residentTypes = [
    { value: '', label: '--' },
    { value: 'TENANT', label: 'Locataire' },
    { value: 'HOMEOWNER', label: 'Propriétaire occupant' },
    { value: 'OWNER', label: 'Propriétaire non-occupant' },
  ];

  return (
    <React.Fragment>
      <div className="container step step-2 pl-4-md pr-4-md">
        <div className="row">
          <div className="col-12 text-align-center pt-6">
            <img src={imgBuilding} alt="Inscription - Etape 2" />
          </div>

          <div className="col-12 text-align-left roboto pt-5">
            <div className="font-size-lg bold font-size-md-sm text-align-center">
              Encore quelques détails...
            </div>
            <div className="mt-5 font-size-sm-sm">
              <p>
                Merci de compléter les informations ci-dessous concernant votre
                adresse <span className="yellowTag">{property.name}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <form onSubmit={validStep}>
            <div className="col-12">
              <div className="row">
                {property.buildings?.length <= 1 ? (
                  ''
                ) : (
                  <div className="col-12">
                    <label htmlFor="building">
                      Bâtiment / Groupe de résidents *
                    </label>
                    <FieldError fieldName="building" errors={formData.errors} />
                    <div className="select-container">
                      <select
                        name="building"
                        id="building"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            buildingId: e.target.value,
                          })
                        }
                        required
                      >
                        <option
                          key={'building-id-default'}
                          selected={!formData.buildingId}
                          value={''}
                          disabled
                        >
                          --
                        </option>
                        {property.buildings.map((value, index) => (
                          <option
                            key={`building-id-${value.buildingId}`}
                            selected={formData.buildingId === value.buildingId}
                            value={value.buildingId}
                          >
                            {value.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-6 col-12-md">
                  <label htmlFor="floor">Étage *</label>
                  <FieldError fieldName="floor" errors={formData.errors} />
                  <div className="select-container">
                    <select
                      name="floor"
                      id="floor"
                      onChange={(e) =>
                        setFormData({ ...formData, floor: e.target.value })
                      }
                      required
                    >
                      {floors.map((value, index) => (
                        <option
                          key={'floor-id-' + value.value}
                          value={value.value}
                          selected={
                            parseInt(value.value) === parseInt(formData.floor)
                          }
                        >
                          {value.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-6 col-12-md pl-2 pl-0-md">
                  <label htmlFor="email">
                    Porte / N° d'appartement
                    {property.enforceApartmentNumberSelection ? ' *' : ''}
                  </label>
                  <FieldError
                    fieldName="apartmentNumber"
                    errors={formData.errors}
                  />
                  <input
                    type="text"
                    name="apartmentNumber"
                    id="apartmentNumber"
                    className="text-input left-icon"
                    maxLength={20}
                    required={!!property.enforceApartmentNumberSelection}
                    placeholder=""
                    value={formData.apartmentNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        apartmentNumber: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-12 col-12-md">
                  <label htmlFor="residentType">Statut de résident *</label>
                  <FieldError
                    fieldName="residentType"
                    errors={formData.errors}
                  />
                  <div className="select-container">
                    <select
                      name="residentType"
                      id="residentType"
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          residentType: event.target.value,
                        })
                      }
                      required
                    >
                      {residentTypes.map((value, index) => (
                        <option
                          key={'residentType-id-' + value.value}
                          value={value.value}
                          selected={formData.residentType === value.value}
                        >
                          {value.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className={
                    'col-12 col-12-md ' +
                    (formData.residentType === 'OWNER' ||
                    formData.residentType === 'HOMEOWNER'
                      ? ' show'
                      : ' hide')
                  }
                >
                  <label
                    className="toggle float-left "
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        isMemberOfCondominiumCouncil: e.target.checked,
                      })
                    }
                  >
                    <input type="checkbox" className="toggle-input" />
                    <span className="slider" />
                  </label>

                  <span className="inline pl-3 text-color-purple-grey font-size-md">
                    {' '}
                    Membre du conseil syndical{' '}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="hide show-md mb-30"></div>
              </div>
            </div>

            <div className="step2-footer ml-auto mr-auto text-align-center">
              <div className="col-12 pt-5">
                <div>
                  <button
                    className="btn secondary mr-2"
                    onClick={(event) =>
                      setFormData({ ...formData, status: 'USER_INFO' })
                    }
                  >
                    Précédent
                  </button>
                  <button
                    className="btn ml-2"
                    type="submit"
                    disabled={formData.isLoading}
                  >
                    Suivant
                  </button>
                </div>

                <StepsList selectedStep={2} nbOfSteps={3} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step2;
