import React from 'react';

const StepsList = ({ selectedStep, nbOfSteps }) => {
  return (
    <div className="steps-list pt-2">
      <ul>
        {Array(nbOfSteps)
          .fill(1)
          .map((i, idx) =>
            idx + 1 === selectedStep ? (
              <li key={'steps-list-' + idx} className="selected">
                <span></span>
              </li>
            ) : (
              <li key={'steps-list-' + idx}>
                <span></span>
              </li>
            ),
          )}
      </ul>
    </div>
  );
};

export default StepsList;
