import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { SignUpLayout } from '../../components/Layout';
import BlockUi from 'react-block-ui';
import has from 'lodash/has';
import queryString from 'query-string';
import {
  Step1,
  Step2,
  Step3,
  ValidEmail,
  AddressValidationInProgress,
} from '../../components/SignUp';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import api from '../../utils/api';
import handleFormError from '../../utils/handleFormError';

const Form = ({ location }) => {
  let metaTitle = `Inscrivez-vous sur Alacaza`;
  let metaDescription = ``;

  const [fromMobileApp, setFromMobileApp] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [formData, setFormData] = useState({
    isLoading: true,
    errors: [],
    status: 'USER_INFO',
    signUpRequestId: null,
    propertyId: null,
    gender: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    buildingId: null,
    floor: null,
    apartmentNumber: '',
    residentType: 'TENANT',
    isMemberOfCondominiumCouncil: false,
    property: { id: null, name: '' },
    zone: { id: null, name: '', location: [] },
  });

  const initForm = async () => {
    const validParams = queryString.parse(location.search);
    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }

    let { property, zone } = location?.state || {};

    if (!property && validParams.p && validParams.code) {
      try {
        const { data } = await api.get(
          `/properties/with-code/${validParams.p}`,
          {
            params: {
              code: validParams.code,
            },
          },
        );
        property = data;
        zone = property.zone;
      } catch (e) {
        navigate('/');
        return;
      }
    }

    if (!property || !zone) {
      navigate('/');
      return;
    }

    setFormData({
      ...formData,
      propertyId: property.id,
      property,
      zone,
      code: validParams.code,
      isLoading: false,
    });
  };

  const validStep1 = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, isLoading: true });

    try {
      const { data } = await api.post('/users/signup/step1', {
        signUpRequestId: formData.signUpRequestId,
        propertyId: formData.propertyId,
        code: formData.code,
        gender: formData.gender,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
      });

      setFormData({
        ...formData,
        isLoading: false,
        errors: [],
        signUpRequestId: data.id,
        status: data.status,
      });

      trackCustomEvent({
        category: 'signUp',
        action: 'valid step 1',
        label: formData.propertyId,
      });
    } catch (error) {
      handleFormError(error);
      setFormData({ ...formData, isLoading: false, errors: [] });
    }
  };

  const validStep2 = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, isLoading: true });

    try {
      const { data } = await api.post('/users/signup/step2', {
        signUpRequestId: formData.signUpRequestId,
        propertyId: formData.propertyId,
        code: formData.code,
        buildingId:
          formData.buildingId ||
          formData.property.buildings.map((b) => b.buildingId)[0],
        floor: formData.floor,
        apartmentNumber: formData.apartmentNumber,
        residentType: formData.residentType,
        isMemberOfCondominiumCouncil: formData.isMemberOfCondominiumCouncil,
      });

      setFormData({
        ...formData,
        isLoading: false,
        errors: [],
        status: data.status,
      });

      trackCustomEvent({
        category: 'signUp',
        action: 'valid step 2',
        label: formData.propertyId,
      });
    } catch (error) {
      handleFormError(error);
      setFormData({ ...formData, isLoading: false, errors: [] });
    }
  };

  const validCode = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, isLoading: true });

    try {
      const { data } = await api.post('/users/signup/step3', {
        signUpRequestId: formData.signUpRequestId,
        propertyId: formData.propertyId,
        code: formData.code,
      });
      setFormData({
        ...formData,
        isLoading: false,
        errors: [],
        status: data.status,
      });

      trackCustomEvent({
        category: 'signUp',
        action: 'valid property code',
        label: formData.propertyId,
      });
    } catch (error) {
      handleFormError(error);
    }
  };

  useEffect(() => {
    initForm();
  }, [location.search]);

  useEffect(() => {
    setFromMobileApp(['ANDROID', 'IOS'].includes(platform));
  }, [platform]);

  if (formData.isLoading) {
    return (
      <BlockUi
        tag="div"
        blocking={formData.isLoading}
        renderChildren={!formData.isLoading}
      />
    );
  }

  if (!formData.property) {
    return null;
  }

  if (!formData.status || formData.status === 'USER_INFO') {
    trackCustomEvent({
      category: 'signUp',
      action: 'start sign up form',
      label: formData.propertyId,
    });

    metaTitle = `Rejoignez vos voisins ${formData.property.name} sur Alacaza`;
    metaDescription =
      `Rejoignez vos voisins ${formData.property.name} dans le quartier ${formData.zone.name}` +
      ` et découvrez la première application dédiée à la vie de votre résidence et de votre quartier`;

    return (
      <SignUpLayout
        siteTitle={metaTitle}
        siteDescription={metaDescription}
        fromMobileApp={fromMobileApp}
      >
        <Step1
          formData={formData}
          setFormData={setFormData}
          validStep={validStep1}
          platform={platform}
        />
      </SignUpLayout>
    );
  }

  if (formData.status === 'ACCOUNT_DETAILS') {
    return (
      <SignUpLayout
        siteTitle={metaTitle}
        siteDescription={metaDescription}
        fromMobileApp={fromMobileApp}
      >
        <Step2
          formData={formData}
          setFormData={setFormData}
          validStep={validStep2}
          platform={platform}
        />
      </SignUpLayout>
    );
  }

  if (formData.status === 'VALID_ADDRESS') {
    return (
      <SignUpLayout
        siteTitle={metaTitle}
        siteDescription={metaDescription}
        fromMobileApp={fromMobileApp}
      >
        <Step3
          formData={formData}
          setFormData={setFormData}
          validCode={validCode}
          platform={platform}
        />
      </SignUpLayout>
    );
  }

  if (formData.status === 'ADDRESS_VALIDATION_IN_PROGRESS') {
    return (
      <SignUpLayout
        siteTitle={metaTitle}
        siteDescription={metaDescription}
        fromMobileApp={fromMobileApp}
      >
        <AddressValidationInProgress formData={formData} platform={platform} />
      </SignUpLayout>
    );
  }

  if (formData.status === 'VALID_EMAIL') {
    return (
      <SignUpLayout
        siteTitle={metaTitle}
        siteDescription={metaDescription}
        fromMobileApp={fromMobileApp}
      >
        <ValidEmail formData={formData} platform={platform} />
      </SignUpLayout>
    );
  }

  return null;
};

export default Form;
