import React, { useEffect } from 'react';
import FieldError from '../../components/Error/FieldError';
import imgCode from '../../assets/img/icons/code.svg';
import StepsList from './StepsList';
import '@fortawesome/fontawesome-svg-core/styles.css';

const Step3 = ({ formData, setFormData, validCode, platform }) => {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="container step step-3 pl-5-md pr-5-md">
        <div className="row">
          <div className="col-12 text-align-center pt-6 pt-2-md">
            <img src={imgCode} alt="Inscription - Etape 3" />
          </div>

          <div className="col-12 text-align-left roboto pt-5 pt-3-md">
            <div className="font-size-lg bold font-size-md-sm text-align-center">
              Merci {formData.firstName} ! Plus qu'une étape !
            </div>
            <div className="mt-5 font-size-lg font-size-sm-sm">
              <p>
                Afin que seuls vos vrais voisins aient accès à Alacaza, nous
                devons vérifier votre adresse. Choisissez la méthode de
                vérification ci-dessous qui vous convient le mieux.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr />

            <form onSubmit={validCode}>
              <div className="font-size-md2">
                Si vous avez reçu un code d’inscription dans votre boîte aux
                lettres ou bien avez été invité par un voisin, saisissez votre
                code ci-dessous :
              </div>

              <div className="col-2 pt-3 pb-5 hidden-sm"></div>

              <div className="col-8 cold-12-sm pt-3 pb-5">
                <div className="inline size-50 pr-1">
                  <label htmlFor="email">Code d'inscription *</label>
                  <FieldError fieldName="code" errors={formData.errors} />
                  <input
                    type="text"
                    name="code"
                    id="code"
                    className="text-input"
                    minLength={3}
                    maxLength={50}
                    value={formData.code}
                    onChange={(e) =>
                      setFormData({ ...formData, code: e.target.value })
                    }
                  />
                </div>
                <div className="inline size-50 pl-1 text-align-center">
                  <button
                    type="submit"
                    disabled={formData.isLoading}
                    className="btn"
                  >
                    Valider
                  </button>
                </div>
              </div>

              <div className="col-2 pt-3 pb-5 hidden-sm"></div>
            </form>
          </div>

          {/* <div className="col-12">
            <hr/>
            <div className="font-size-md2">Eius populus ab incunabulis primis ad usque pueritiae tempus extremum, quod annis circumcluditur fere trecentis, circummurana pertulit bella,</div>

            <div className="col-12 pt-3 pb-5 text-align-center-md">
              <button type="submit" className="btn purple">
                <span className="pr-2"><FontAwesomeIcon icon={faEnvelope} /></span> Validation method 1
              </button>
            </div>
          </div>

          <div className="col-12">
            <hr/>

            <div className="font-size-md2">
              circummurana pertulit bella, deinde aetatem ingressus adultam post multiplices bellorum aerumnas Alpes transcendit et fretum.
            </div>

            <div className="col-12 pt-3 pb-5 text-align-center-md">
              <button type="submit" className="btn purple">
                <span className="pr-2"><FontAwesomeIcon icon={faPhone} /></span> Validation method 2
              </button>
            </div>
          </div> */}
        </div>

        <div className="row hide show-md">
          <div className="col-12">
            <div className="mb-30"></div>
          </div>
        </div>

        <div className="step3-footer ml-auto mr-auto text-align-center">
          <div className="col-12 pt-5">
            <div>
              <button
                className="btn secondary"
                onClick={(event) =>
                  setFormData({ ...formData, status: 'ACCOUNT_DETAILS' })
                }
              >
                Précédent
              </button>
              <button className="btn disabled ml-2" disabled={true}>
                Suivant
              </button>
            </div>

            <StepsList selectedStep={3} nbOfSteps={3} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step3;
